import React, { Component } from 'react';

class QuestionTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
            answer: "",
            answears: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    getAnswer(value){
        if(value == 1){
            return "Áno";
        }
        else if(value == 2){
            return "Nie";
        }
    }
    componentDidMount(){
        var answears = this.props.getAnswers();
        console.log(answears);
        this.setState({
            answears: answears,
        });
        if(answears[0]!=""){
            this.setState({answer: answears[1]});
        }
    }

    handleChange(event){

        this.setState({answer: event.target.value});
        this.props.setQuestionState(1,event.target.value);
        this.props.setTwo(event.target.value);
    }
    editanswear = () =>{

        this.props.backanswearone();
        this.props.setQuestionState(2,"");
    }
    handleSubmit(event){
        event.preventDefault();
        this.props.setQuestionState(2,this.state.answer);
        this.props.setTwo();
    }

    render(){
        return(
            <div className="container App App-header">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="otazka">Bude predpokladaný počet prijatých zásielok viac ako 9ks za mesiac?</h2>
                        <p className="odpoved">Označte odpoveď</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 centerize">

                        <form onSubmit={this.handleSubmit}>


                            <div className="radio"> <input onChange={this.handleChange} type="radio" name="firstquest" checked={this.state.answer==1} value="1" /><p className="rad">Áno</p></div>
                            <div className="radio"> <input onChange={this.handleChange} type="radio" name="firstquest" checked={this.state.answer==2} value="2" /><p className="rad">Nie</p> </div>

                            {this.state.answer != "" ? (
                                    <div>

                                        <input type="submit" className="btn btn-primary " value="Ďalej" />
                                    </div>
                                )
                                :(<div>

                                </div>)
                            }





                        </form>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.answer != "" ? (
                                <div>
                                    <p className="ans">Vaša odpoveď:</p>
                                    <p className="ans2">{this.getAnswer(this.state.answer)}</p>

                                </div>
                            )
                            :(<div>

                            </div>)
                        }
                        <a className="edit" onClick={this.editanswear}><i class="fas fa-angle-left"></i>Upraviť predchadzajúcu odpoveď</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuestionTwo;