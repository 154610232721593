import React, { Component } from 'react';
import axios from "axios/index";

class QuestionValidate extends Component{
    constructor(props){
        super(props);
        this.array = this.props.getAnswers();


        this.state = {
            answer: this.doValidation(),
            message: false,
            program: "",
        }

        axios.post('https://bausch.btect.sk/api/addresult', {
            answer_one: this.array[0],
            answer_two: this.array[1],
            answer_three: this.array[2],
            answer_four: this.array[3],
            result: this.state.answer,
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount(){
        this.setState({
            program: this.props.getProgram,
        })
    }

    /*

    Pokud 1) a v kombinaci s 2) a odpovědí  je“ Měsíční silikon hydrogelové kontaktní čočky“
·        Pokud 1) a v kombinaci s 2) b odpovědí je „Jednodenní hydrogelové kontaktní čočky“
·        Pokud 1) b v kombinaci s 2) a odpovědí je „Měsíční silikon hydrogelové kontaktní čočky“
·        Pokud 1) b v kombinaci s 2) b odpovědí je „Jednodenní hydrogelové kontaktní čočky“
·        Pokud 1) c v kombinaci s 2) a odpovědí  je „Měsíční torické silikon hydrogelové kontaktní čočky“
·        Pokud 1) c v kombinaci s 2) b odpovědí  je „Jednodenní torické hydrogelové kontaktní čočky“
·        Pokud 1) d v kombinaci s 2) a odpovědí je „Měsíční presbyopické silikon hydrogelové kontaktní čočky“
·        Pokud 1) d v kombinaci s 2) b odpovědí  je „Jednodenní presbyopické hydrogelové kontaktní čočky“

    */

    getCurrentProgram = () => {

        if(this.state.program == 1){
            var string = "základný program <br/> <span class='blueize'>4,00€/MESIAC</span>";
            return 1;
        }
        else if(this.state.program == 2){
            return 2;
            return "štandardný program<br/><span className='blueize'>10,90€/MESIAC</span> ";
        }
        else if(this.state.program == 3){
            return 3;
            return "prémiový program<br/><span className='blueize'>19,90€/MESIAC</span>";
        }
        else{
            return "error";
        }
    }

    editanswear = () =>{

        this.props.backanswearfinal();
    }



    validateQuestion = (a1,a2,a3,a4) => {
        if(a1 == 1 && a2 == 1){
            return 1;
        }
        else if(a1 == 1 && a2 == 2){
            return 2;
        }
        else if(a1 == 2 && a2 == 1){
            return 3;
        }
        else if(a1 == 2 && a2 == 2){
            return 4;
        }
        else if(a1 == 3 && a2 == 1){
            return 5;
        }
        else if(a1 == 3 && a2 == 2){
            return 6;
        }
        else if(a1 == 4 && a2 == 1){
            return 7;
        }
        else if(a1 == 4 && a2 == 2){
            return 8;
        }
    }

    getValidatedAnswer = (number) => {
        if (number == 1)
            return "Mesačné silikón hydrogélové kontaktné šošovky";
        else if (number == 2)
            return "Jednodňové hydrogélové kontaktné šošovky";
        else if(number == 3)
            return "Mesačné silikón hydrogélové kontaktné šošovky";
        else if (number == 4)
            return "Jednodňové hydrogélové kontaktné šošovky";
        else if(number == 5)
            return "Mesačné torické silikon hydrogélové kontaktné šošovky";
        else if(number == 6)
            return "Jednodenné torické hydrogélové kontaktné šošovky";
        else if(number == 7)
            return "Mesačné presbyopické silikón hydrogélové kontaktné šošovky";
        else if(number == 8)
            return "Jednodňové presbyopické hydrogélové kontaktné šošovky";
    }

    doValidation = () => {
        var answer = "";
        var number = this.validateQuestion(this.array[0],this.array[1], this.array[2],this.array[3]);
        answer = this.getValidatedAnswer(number);
        return answer;
    }

    getAnswerOne(value){
        if(value == 1){
            return "Krátkozrakost";
        }
        else if(value == 2){
            return "Dalekozrakost";
        }
        else if(value == 3){
            return "Astigmatismus";
        }
        else if(value == 4){
            return "Presbyopii";
        }
    }
    getAnswerTwo(value){
        if(value == 1){
            return "Denně";
        }
        else if(value == 2){
            return "Občas; v pravidelných intervalech ale ne denně (např. na sport)";
        }
    }
    getAnswerThree(value){
        if(value == 1){
            return "1 - 2";
        }
        else if(value == 2){
            return "2 - 4";
        }
        else if(value == 3){
            return "4 – více";
        }

    }
    getAnswerFour(value){
        if(value == 1){
            return "Uvnitř budov";
        }
        else if(value == 2){
            return "Na čerstvém vzduchu";
        }
        else if(value == 3){
            return "V autě";
        }

    }
    toggleMessage = () => {
        this.setState({message: true});

    }

    render(){
        if(this.state.message == true) {
            return (
                <div className="container App App-header">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="nadpis center">
                                {this.state.program == 1 ? <div>
                                    <span className="blackize">základný program</span> <br/> <span class='blueize'>4,00€/MESIAC</span>
                                </div>: null}
                                {this.state.program == 2 ? <div>
                                    <span className="blackize">štandardný program</span> <br/> <span class='blueize'>10,90€/MESIAC</span>
                                </div>: null}
                                {this.state.program == 3 ? <div>
                                    <span className="blackize">prémiový program</span> <br/> <span class='blueize'>19,90€/MESIAC</span>
                                </div>: null}

                            </h1>
                            <h3 className="need"> bude pre vás ideálny</h3>
                            <br/><br/>
                            <a className="edit" onClick={this.editanswear}>Upraviť predchadzajúcu odpoveď</a>


                        </div>
                    </div>
                </div>
            );
        }
        else{
            return (
                <div className="container App App-header">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="nadpis center">
                                {this.state.program == 1 ? <div>
                                    <span className="blackize">základný program</span> <br/> <span class='blueize'>4,00€/MESIAC</span>
                                </div>: null}
                                {this.state.program == 2 ? <div>
                                    <span className="blackize">štandardný program</span> <br/> <span class='blueize'>10,90€/MESIAC</span>
                                </div>: null}
                                {this.state.program == 3 ? <div>
                                    <span className="blackize">prémiový program</span> <br/> <span class='blueize'>19,90€/MESIAC</span>
                                </div>: null}

                            </h1>
                            <h3 className="need"> bude pre vás ideálny</h3>
                            <br/><br/>
                            <a className="edit" onClick={this.editanswear}><i class="fas fa-angle-left"></i>Upraviť predchadzajúcu odpoveď</a>




                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default QuestionValidate;