import React, { Component } from 'react';
import logo from './logo.png';
import './App.css';
import Questions from "./Questions/Questions";

class App extends Component {
  constructor(props){
    super(props);

      this.state = {
          started: true,
          one: "",
          two: "",
          three: "",
          four: "",
          fifth: "",
          program: "",
          finish: false,
      }

  }

  setStarted = () => {
      this.setState({started: true});
  }

  setFinish = () => {

  }

  getAnswers = () => {


      var array = new Array();
      array.push(this.state.one);
      array.push(this.state.two);
      array.push(this.state.three);
      array.push(this.state.four);
      array.push(this.state.five);
      return array;
  }





  setQuestionState = (which, value) => {
      if(which == 1){
          this.setState({one: value});
          if(value == 1){
              this.setState({
                  program: value,
              })
          }
      }
      else if(which == 2){
          this.setState({two: value});
      }
      else if(which == 3){
          this.setState({three: value});
      }
      else if(which == 4){
          this.setState({four: value});
          if(value == 2){
              this.setState({
                  program: value,
              })
          }

      }
      else if(which == 5){
          this.setState({five: value});
          if(value == 1){
              this.setState({
                  program: 3,
              })
          }
          else if(value == 2){
              this.setState({
                  program: 2,
              })
          }

      }
  }

  render() {
    if(this.state.started == false) {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>

                    <a
                        className=" btn btn-primary"
                        onClick={this.setStarted}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Začať
                    </a>
                </header>
            </div>
        );
    }
    else{
        return (
            <Questions getProgram={this.state.program} getAnswers={this.getAnswers} setQuestionState={this.setQuestionState} />
        );
    }
  }
}

export default App;
