import React, { Component } from 'react';
import QuestionFirst from "./QuestionFirst";
import QuestionSecond from "./QuestionSecond";
import QuestionThird from "./QuestionThird";
import QuestionFourth from "./QuestionFourth";
import QuestionReview from "./QuestionReview";
import QuestionValidate from "./QuestionValidate";
import QuestionFifth from "./QuestionFifth";

class Questions extends Component{
    constructor(props){
        super(props);
        this.state = {
            one: false,
            two: false,
            three: false,
            four: false,
            five: false,
            validate: false,

        }
    }

    setOne = (answer) => {
        this.setState({one: true});
        if(answer == 1){
            this.setState({validate: true});
        }
    }
    editQuestion = (value) =>{
        if(value == 1){
            this.setState({one: false});
        }
        else if(value == 2){
            this.setState({two: false});
        }
        else if(value == 3){
            this.setState({three: false});
        }
        else if(value == 4){
            this.setState({four: false});
        }

    }
    setTwo = () => {
        this.setState({two: true});
    }
    setThree = () => {
        this.setState({three: true});
    }
    setFour = (answer) => {
        this.setState({four: true});
        if(answer == 2){
            this.setState({validate: true});
        }
    }
    setFive = (answer) => {
        this.setState({five: true});
        this.setState({validate: true});
    }
    backanswearone= () => {

        this.setState({
            one: false,
        })
    }
    backansweartwo= () => {

        this.setState({

            two: false,
        })
    }
    backanswearthree= () => {

        this.setState({
            three: false,
        })
    }
    backanswearfour= () => {

        this.setState({
            four: false,
        })
    }

    backanswearfinal = () => {
        if(this.state.five == false){
            this.setState({
                one: false,
                validate: false,
            })
        }
        else{
            this.setState({
                five: false,
                validate: false,
            })
        }
    }

    setValidate = () => {
        this.setState({validate: true});
    }

    render(){
        if(this.state.validate == true){
            return(
                <div>
                    <QuestionValidate backanswearfinal={this.backanswearfinal} getProgram={this.props.getProgram} getAnswers={this.props.getAnswers}/>
                </div>
            );
        }
        else if(this.state.one == false) {
            return (
                <QuestionFirst getProgram={this.props.getProgram} getAnswers={this.props.getAnswers} setOne={this.setOne} setQuestionState={this.props.setQuestionState}/>
            );
        }
        else if(this.state.two == false) {
            return (
                <QuestionSecond getProgram={this.props.getProgram} getAnswers={this.props.getAnswers} backanswearone={this.backanswearone} setTwo={this.setTwo} setQuestionState={this.props.setQuestionState}/>
            );
        }
        else if(this.state.three == false) {
            return (
                <QuestionThird getProgram={this.props.getProgram} getAnswers={this.props.getAnswers} backansweartwo={this.backansweartwo} setThree={this.setThree} setQuestionState={this.props.setQuestionState}/>
            );
        }
        else if(this.state.four == false) {
            return (
                <QuestionFourth getProgram={this.props.getProgram} getAnswers={this.props.getAnswers} backanswearthree={this.backanswearthree} setFour={this.setFour} setQuestionState={this.props.setQuestionState}/>
            );
        }
        else if(this.state.five == false) {
            return (
                <QuestionFifth backanswearfour={this.backanswearfour} getAnswers={this.props.getAnswers} setFifth={this.setFive} setQuestionState={this.props.setQuestionState}/>
            );
        }
        else if(this.state.one == true && this.state.two == true && this.state.three == true && this.state.four == true && this.state.five == true) {
         return(
            <QuestionReview  setValidate={this.setValidate} editQuestion ={this.editQuestion} getAnswers={this.props.getAnswers}/>
         );
        }
    }
}

export default Questions;