import React, { Component } from 'react';

class QuestionFifth extends Component{
    constructor(props){
        super(props);
        this.state = {
            answer: "",
            answears: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event){

        this.setState({answer: event.target.value});
        this.props.setQuestionState(5,event.target.value);
        this.props.setFifth(event.target.value);
    }
    componentDidMount(){
        var answears = this.props.getAnswers();
        console.log(answears);
        this.setState({
            answears: answears,
        });
        if(answears[4]!="" && answears[4] != undefined){
            this.setState({answer: answears[4]});
        }
    }
    handleSubmit(event){
        event.preventDefault();
        this.props.setQuestionState(5,this.state.answer);
        this.props.setFifth(this.state.answer);
    }
    getAnswer(value){

        if(value == 1){
            return "Do 30. minút";
        }
        else if(value == 2){
            return "Druhý pracovný deň";
        }

    }
    editanswear = () =>{

        this.props.backanswearfour();
        this.props.setQuestionState(5,"");
    }

    render(){
        return(
            <div className="container App App-header">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="otazka">Chceli by ste mať zásielku naskenovanú do 30. minút, alebo Vám ju bude postačovať oskenovať do druhého pracovného dňa?</h2>
                        <p className="odpoved">Označte odpoveď</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 centerize">

                        <form onSubmit={this.handleSubmit}>

                            <div className="radio"> <input onChange={this.handleChange} type="radio" name="firstquest" checked={this.state.answer==1} value="1" /><p className="rad">Do 30. minút</p></div>
                            <div className="radio"> <input onChange={this.handleChange} type="radio" name="firstquest" checked={this.state.answer==2} value="2" /><p className="rad">Druhý pracovný deň</p></div>

                            {this.state.answer != "" ? (
                                    <div>

                                        <input type="submit" className="btn btn-primary" value="Ďalej" />
                                    </div>
                                )
                                :(<div>

                                </div>)
                            }



                        </form>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.answer != "" ? (
                                <div>
                                    <p className="ans">Vaša odpoveď:</p>
                                    <p className="ans2">{this.getAnswer(this.state.answer)}</p>

                                </div>
                            )
                            :(<div>

                            </div>)
                        }
                        <a className="edit" onClick={this.editanswear}><i class="fas fa-angle-left"></i>Upraviť predchadzajúcu odpoveď</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuestionFifth;