import React, { Component } from 'react';

class QuestionReview extends Component{
    constructor(props){
        super(props);
        this.state = {
            answer: "",
        }
        this.array = this.props.getAnswers();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.setValidate();
    }

    getAnswerOne(value){
        if(value == 1){
            return "Krátkozrakost";
        }
        else if(value == 2){
            return "Dalekozrakost";
        }
        else if(value == 3){
            return "Astigmatismus";
        }
        else if(value == 4){
            return "Presbyopii";
        }
    }
    getAnswerTwo(value){
        if(value == 1){
            return "Denně";
        }
        else if(value == 2){
            return "Občas; v pravidelných intervalech ale ne denně (např. na sport)";
        }
    }
    getAnswerThree(value){
        if(value == 1){
            return "1 - 2";
        }
        else if(value == 2){
            return "2 - 4";
        }
        else if(value == 3){
            return "4 – více";
        }

    }
    getAnswerFour(value){
        if(value == 1){
            return "Uvnitř budov";
        }
        else if(value == 2){
            return "Na čerstvém vzduchu";
        }
        else if(value == 3){
            return "V autě";
        }

    }

    handleChange(event){

        this.setState({answer: event.target.value});
    }
    handleSubmit(event){
        event.preventDefault();
        this.props.setQuestionState(1,this.state.answer);
        this.props.setOne();
    }
    getAnswer(value){
        if(value == 1){
            return "Krátkozrakost";
        }
        else if(value == 2){
            return "Dalekozrakost";
        }
        else if(value == 3){
            return "Astigmatismus";
        }
        else if(value == 4){
            return "Presbyopii";
        }
    }

    result = () => {

    }

    render(){
        return(
            <div className="container App App-header">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="otazka">Vaše odpovede na otázky</h2>

                        <p>1,{this.getAnswerOne(this.array[0])} <a className="btn btn-primary" onClick={() => this.props.editQuestion(1)}>Upravit</a></p>
                        <p>2,{this.getAnswerTwo(this.array[1])} <a className="btn btn-primary" onClick={() => this.props.editQuestion(2)}>Upravit</a></p>
                        <p>3,{this.getAnswerThree(this.array[2])} <a className="btn btn-primary" onClick={() => this.props.editQuestion(3)}>Upravit</a></p>
                        <p>4,{this.getAnswerFour(this.array[3])} <a className="btn btn-primary" onClick={() => this.props.editQuestion(4)}>Upravit</a></p>

                        <a className="btn btn-danger" onClick={this.props.setValidate}>Vyhodnotit</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuestionReview;