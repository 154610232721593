import React, { Component } from 'react';

class QuestionFirst extends Component{
    constructor(props){
        super(props);
        this.state = {
            answer: "",
            answears: "",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        var answears = this.props.getAnswers();
        console.log(answears);
        this.setState({
           answears: answears,
        });
        if(answears[0]!=""){
            this.setState({answer: answears[0]});
        }
    }

    handleChange(event){

        this.setState({answer: event.target.value});

        this.props.setQuestionState(1,event.target.value);
        this.props.setOne(event.target.value);
    }
    handleSubmit(event){
        event.preventDefault();
        this.props.setQuestionState(1,this.state.answer);
        this.props.setOne(this.state.answer);
    }
    getAnswer(value){
        if(value == 1){
            return "Nie";
        }
        else if(value == 2){
            return "Áno";
        }

    }

    render(){
        return(
            <div className="container App App-header">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="otazka">Chcete byť o svojich zásielkach notifikovaný SMS správou a mať prístup do online aplikácie?</h2>
                        <p className="odpoved">Označte odpoveď</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 centerize">
                            <form onSubmit={this.handleSubmit}>


                                    <br/>
                                <div className="radio"><input onChange={this.handleChange} type="radio" name="firstquest" value="2" checked={this.state.answer==2}  /><p className="rad"> Áno</p></div>
                                <div className="radio"><input onChange={this.handleChange} type="radio" name="firstquest" value="1" checked={this.state.answer==1} /><p className="rad">Nie</p></div>
                                {this.state.answer != "" ? (
                                        <div>

                                            <input type="submit" className="btn btn-primary " value="Ďalej" />
                                        </div>
                                    )
                                    :(<div>

                                    </div>)
                                }

                        </form>

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 centerize">
                        {this.state.answer != "" ? (
                                <div>
                                    <p className="ans">Vaša odpoveď:</p>
                                    <p className="ans2">{this.getAnswer(this.state.answer)}</p>

                                </div>
                            )
                            :(<div>

                            </div>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default QuestionFirst;